<template>
  <v-app style="background-color: #f2f2f2">
    <v-layout row wrap align-center justify-center>
      <v-flex lg4 md5 sm7 xs7 class="text-center">
        <img
          src="@/assets/logo_onebox-horizontal-png.png"
          width="45%"
          height="45%"
          alt="OneBox"
        />
        <br />
        <br />
        <v-progress-linear
          background-color="light-blue lighten-4"
          color="success"
          indeterminate
          rounded
        ></v-progress-linear>
        <br />
        <p class="text-center">รอสักครู่</p>
      </v-flex>
    </v-layout>
  </v-app>
</template>

<script>
import Swal from "sweetalert2/dist/sweetalert2.js";
import "sweetalert2/src/sweetalert2.scss";

const Toast = Swal.mixin({
  toast: true,
  position: "top-end",
  showConfirmButton: false,
  timer: 3000
});

export default {
  data: function() {
    return {};
  },
  created() {},
  computed: {},
  watch: {},
  methods: {
    loginwithdomain(
      param_onesharetoken,
      param_domain,
      param_displayname,
      param_email,
      param_onemailtoken
    ) {
      let payload = {
        domain: param_domain,
        displayName: param_displayname,
        email: param_email,
        accesstoken: param_onemailtoken,
        shared_token_oneid:param_onesharetoken,
      };
      localStorage.removeItem("color");
      this.$store
        .dispatch("authorize_login_business_email_withdomain_migrateoneid", payload)
        .then(res => {
          if (res.status === "Access Granted") {
            // ส่ง event ไปยัง Google Analytics
            this.$gtag.event('login', {
              event_category: "bydomain",
            });

            if (this.$route.query.color !== undefined) {
                  localStorage.setItem("color", this.$route.query.color);
                }
            this.$router.push("/mydrive");
          } else {
            Toast.fire({ icon: "error", title: res.msg });
            this.$router.push("/");
          }
        })
        .catch(err => {
          console.log(err);
          Toast.fire({ icon: "error", title: err });
          this.$router.push("/");
        });
    }
  },
  mounted() {
    if (
      "domain" in this.$route.query &&
      "displayname" in this.$route.query &&
      "email" in this.$route.query &&
      "onemailtoken" in this.$route.query
    ) {
      this.loginwithdomain(
        this.$route.query.sharetoken,
        this.$route.query.domain,
        this.$route.query.displayname,
        this.$route.query.email,
        this.$route.query.onemailtoken
      );
    } else {
      Toast.fire({ icon: "error", title: "Access Denied" });
      this.$router.push("/");
    }
    // this.loginwithsharetoken()
  }
};
</script>

